import React from 'react'
import appConfig, { Labels } from '../utils/config'
import { Text } from '../components'

const NotFound: React.FC = () => {
  return (
    <div className="d-flex justify-content-center">
      <Text type='h6' className="mt-5">{labels.notFound}</Text>
    </div>
  )
}

export default NotFound

const LABELS: Labels = {
  en: {
    notFound: 'Page not found 🤷‍♂️',
  },
  pt: {
    notFound: 'Página não encontrada 🤷‍♂️',
  },
}

const labels = LABELS[appConfig.language]
